        /*\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
          Custom Arabic font
        \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/
        @font-face {
            font-family: 'Cairo-Regular';
            src: url('../../assets/fonts/Cairo-Regular.eot');
            src: url('../../assets/fonts/Cairo-Regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Cairo-Regular.woff2') format('woff2'), url('../../assets/fonts/Cairo-Regular.woff') format('woff'), url('../../assets/fonts/Cairo-Regular.ttf') format('truetype'), url('../../assets/fonts/Cairo-Regular.svg') format('svg');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: 'Cairo-Regular';
            src: url('../../assets/fonts/Cairo-Light.eot');
            src: url('../../assets/fonts/Cairo-Light.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Cairo-Light.woff2') format('woff2'), url('../../assets/fonts/Cairo-Light.woff') format('woff'), url('../../assets/fonts/Cairo-Light.ttf') format('truetype'), url('../../assets/fonts/Cairo-Light.svg') format('svg');
            font-weight: 300;
            font-style: normal;
        }
        @font-face {
            font-family: 'Cairo-Regular';
            src: url('../../assets/fonts/Cairo-ExtraLight.eot');
            src: url('../../assets/fonts/Cairo-ExtraLight.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Cairo-ExtraLight.woff2') format('woff2'), url('../../assets/fonts/Cairo-ExtraLight.woff') format('woff'), url('../../assets/fonts/Cairo-ExtraLight.ttf') format('truetype'), url('../../assets/fonts/Cairo-ExtraLight.svg') format('svg');
            font-weight: 200;
            font-style: normal;
        }
       
            * {
                box-sizing: border-box;
            }
            /* body, html {
                direction:rtl;
                }*/
                body,html,[id="root"],[id="root"] > div,[id="root"] > div>div{
                    height: 100%;
                }
            body {
                font-family: 'Cairo-Regular';
                color: #000;
                /*width: 297mm;*/
                /* height: 210mm;*/
                margin: 0 auto;
                -webkit-print-color-adjust: exact !important;
            }
    .font-sans-serif {
        font-family: sans-serif;
    }
            .header img {
                width: 75px
            }
    
            h2 {
                text-align: center;
                line-height: 1;
                margin-top: 10px;
                font-size: 15px;
                page-break-inside: avoid;
                color: #5E9654;
                margin: 0;
            }
    
            h3 {
                padding: 0px 9px;
                font-size: 10px;
                page-break-inside: avoid;
                margin: 0;
                background-color: #dfdfdf;
                line-height: initial;
            }
                h3 span:last-child {
                    font-size: 8.3px;
                } 
                h3 + p b{
                    font-size: 11px;
                }
            h4 {
                margin-top: 2px;
                font-size: 11px;
                page-break-inside: avoid;
                margin-bottom: 4px;
                text-transform: uppercase;
            }
    
            P {
                margin: 0;
                font-size: 9px;
                font-weight: normal;
                line-height: 1.5;
                page-break-inside: avoid;
            }
    
                p b {
                    font-size: 9px;
                    display: inline-block;
                    margin: 0 4px;
                    line-height: 1.2;
                }
    
                p span:not(:first-child) {
                    display: inline-block;
                    padding-left: 4px;
                }
    
                    p span:not(.w-50) > b {
                        margin: 0 2px;
                    }
    
            .page {
                page-break-after: always !important;
            }
    
            table {
                border-collapse: collapse;
            }
            table tr {
                line-height: 1;
            }

    
                table th {
                    font-weight: bold;
                }
    
                table td, table th {
                    padding: .20rem;
                    font-size: 9px;
                    font-weight: 400;
                    border-color: #000;
                }
    
                    table td span {
                        display: inline-block;
                    }
    
            .cell-bg {
                background-color: rgba(0,0,0,.07);
            }
    
            table {
                page-break-inside: auto
            }
    
            .main-content tr {
                page-break-inside: avoid;
                page-break-after: auto
            }
    
            thead {
                display: table-header-group
            }
    
            tfoot {
                display: table-footer-group
            }
    
            .page-ba-always {
                page-break-after: always !important;
            }
    
            .page-bb-always {
                page-break-before: always !important;
            }
    
            .page-ba-avoid {
                page-break-after: avoid !important;
            }
    
            .page-bb-avoid {
                page-break-before: avoid !important;
            }
    
            .page-inside-avoid {
                page-break-inside: avoid !important;
            }
    
            .d-inlineblock {
                display: inline-block;
            }
    
            .d-block {
                display: block;
            }
    
            .text-right {
                text-align: right !important;
                direction: rtl;
            }
    
            .text-left {
                text-align: left !important;
            }
    
            .text-center {
                text-align: center !important;
            }
    
            .font-bold {
                font-weight: bold;
            }
    
            .mx-auto {
                margin: 0 auto;
            }
            .mb-0 {
                margin-bottom: 0 !important;
            }
            .valign-middle{
                vertical-align: middle !important;
            }
            .valign-bottom ,.content table .valign-bottom{
                vertical-align: bottom !important;
            }
            .w-75 {
                width: 75%;
                display: inline-block;
                margin: 0 -2px;
                vertical-align: top;
            }
    
            .w-50 {
                width: 50% !important;
                display: inline-block;
                vertical-align: top;
            }
            .w-60 {
                width: 60% !important;
                display: inline-block;
                vertical-align: top;
            }
            .w-70 {
                width: 70% !important;
                display: inline-block;
                vertical-align: top;
            }
            .w-25 {
                width: 25%;
                display: inline-block;
                margin: 0 -1px;
                vertical-align: top;
            }
            .w-20 {
                width: 20%;
                display: inline-block;
                margin: 0 -1px;
                vertical-align: top;
            }
            .w-15 {
                width: 15%;
                display: inline-block;
                margin: 0 -1px;
                vertical-align: top;
            }
            .w-30 {
                width: 30%;
                display: inline-block;
                margin: 0 -1px;
                vertical-align: top;
            }
            .pt-0 {
                padding-top: 0 !important;
            }
    
            .pt-1 {
                padding-top: 5px !important;
            }
    
            .pt-2 {
                padding-top: 10px !important;
            }
    
            .pt-3 {
                padding-top: 15px !important;
            }
    
            .pb-0 {
                padding-bottom: 0 !important;
            }
    
            .pb-1 {
                padding-bottom: 5px !important;
            }
    
            .pb-2 {
                padding-bottom: 10px !important;
            }
    
            .pb-3 {
                padding-bottom: 15px !important;
            }
    
            .p-0 {
                padding: 0 !important;
            }
    
            .px-0 {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
    
            .px-2 {
                padding-right: 10px !important;
                padding-left: 10px !important;
            }
    
            .px-3 {
                padding-right: 15px !important;
                padding-left: 15px !important;
            }
    
            .py-0 {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
    
            .py-2 {
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }
    
            .py-3 {
                padding-top: 15px !important;
                padding-bottom: 15px !important;
            }
    .me-1{
        margin-right: 5px !important;
    }
    .ms-1{
        margin-left: 10px !important;
    }
    .ws-nowrap{
        white-space: nowrap;
    }
            .border-0 {
                border: 0;
            }

            .text-end {
                text-align: end;
            }

            .text-start {
                text-align: start;
            }
            .lineclamp-1 {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                        line-clamp: 1; 
                -webkit-box-orient: vertical;
             }     
             .lineclamp-2 {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                        line-clamp: 2; 
                -webkit-box-orient: vertical;
             }          
            /*===============*/
    
            .content {
                height: 50%;
            }
            .financial-info tr {
                line-height: 1;
            }
            p .w-50 .w-25.text-end,td[align="right"]{
                 font-size: 8.4px;
            }
           .content table:not(table:first-child):not(table:nth-child(2)) td{
                vertical-align: top;
           }
           .content table:nth-child(2) td p [class^=w-] {
            vertical-align: middle;
       }
       rect ~ text{
        display: none !important;
       }
            .devider {
                border-right: 1px solid #c3c1c1;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            .header-space {
                height: 19.5mm;
            }

            .header-space-table {
                height: 27mm;
            }

            .footer-space {
                height: 5mm;
            }

            .footer-space-table {
                height: 15mm;
            }
    
            @page {
                size: auto;
                margin: 4mm 6mm 0mm;
            }
    
            @media only screen {
                body {
                    margin: 0mm 10mm 0mm 10mm;
                }
            }

            #bulk-table-data th, 
            #bulk-table-data  td {
                border-inline-width: 0;
                text-align: center;
                border-color: #dfdfdf;
            }

            #bulk-table-data {
                border: 0;
            }

            .print-per-page {
                page-break-before: avoid;
              }
              
            .print-per-page:first-of-type {
                page-break-before: always;
            }
            